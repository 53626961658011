// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
require("jquery")
require("@nathanvda/cocoon")

import Cropper from 'cropperjs/dist/cropper.esm';
import "bootstrap";
import "../stylesheets/application"
import "../stylesheets/admin"

const images = require.context('../images', true)

$(document).ready(function(){
  tinymce.init({
    selector: '.tinymce',
    toolbar: [
      "undo redo | formatselect fontselect fontsizeselect | bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent blockquote",
      "fullscreen preview | print | save | link image media imagetools | emoticons | table | cut copy paste selectall  | searchreplace"
    ],
    plugins: ['autoresize advlist autolink autosave lists link image',
      'charmap print hr print pagebreak searchreplace wordcount visualblocks visualchars code fullscreen',
      'insertdatetime media nonbreaking insertdatetime save table contextmenu directionality emoticons',
      'template', 'paste', 'textcolor', 'colorpicker textpattern', 'codesample imagetools preview image'
    ],
    images_upload_url: '/uploader/image'
  });
});
